import React from "react"
import { graphql } from "gatsby"
// import DOMPurify from "dompurify"
import Layout from "../components/layout"
// import Image from "gatsby-image"
import SEO from "../components/seo"

const WPpost = ({ data }) => (
  <Layout>
    <SEO
      title={data.wordpressPost.title}
      description={data.wordpressPost.excerpt}
    />
    {data.wordpressPost.title && <h1>{data.wordpressPost.title}</h1>}
    {data.wordpressPost.content && (
      <div
        style={{ marginTop: 20 }}
        dangerouslySetInnerHTML={{ __html: data.wordpressPost.content }}
      />
    )}
  </Layout>
)

export default WPpost

export const query = graphql`
  query($id: Int!) {
    wordpressPost(wordpress_id: { eq: $id }) {
      title
      content
      excerpt
      date(formatString: "MMMM DD, YYYY")
    }
  }
`
